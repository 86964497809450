import { Component, Vue } from 'vue-property-decorator'

//global
import { Variables } from '@/config/variables';

//esic components
import EContent from '@/components/esic/eContent/EContent.vue';
import ECard from '@/components/esic/eCard/ECard.vue';

const namespace = 'auth';
//Vuex
import { Action, Getter } from 'vuex-class';

//wrappers
import {
	WColumn,
	WColumns,
	SizedBox,
	WLine,
	wIcon,
	SmoothDropdown,
	WButton,
	WImage,
} from '@/components/wrappers';

@Component({
	components:{
		ECard,
		WLine,
		wIcon,
		WImage,
		WColumn,
		WButton,
		WColumns,
		SizedBox,
		EContent,
		SmoothDropdown,
	}
})
export default class Esic extends Vue {
	@Action('logout', { namespace }) logout: any;
	
	textLaw = Variables.htmlTextLaw

	mounted(): void {
		document.title = "e-SIC"
		this.logout()
	}

	cards = [
		{
			title:"Registrar pedido",
			icon: "plus-circle",
			route: "/recognition?route=register",
			enabled: true
		},
		{
			title:"Consultar Pedido",
			icon: "magnify",
			route: "/recognition?route=search",
			enabled: true
		},
		{
			title:"Sic Físico Canais de Atendimento",
			icon: "home-map-marker",
			route: "/atendimento",
			enabled: true
		},
		{
			title:"Estatísticas em Tempo Real",
			icon: "chart-areaspline",
			route: "/estatisticas",
			enabled: true
		},
		{
			title:"Relatórios Estatísticos",
			icon: "chart-pie",
			route: "/relatorios",
			enabled: true
		},
	]
}
